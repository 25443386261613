import React from 'react';
import PropTypes from 'prop-types';
import { getStaticImageUrl } from 'client/utils/image-helpers';

export const EdmundsCarMaxLogo = ({ width, height, isLazyImage }) => (
  <picture>
    <img
      className="edmunds-carmax-logo"
      src={getStaticImageUrl('img/eva/partners/cobranded-edmunds-carmax-logo-new.svg')}
      alt="Edmunds and CarMax logo"
      height={height}
      width={width}
      loading={isLazyImage ? 'lazy' : undefined}
    />
  </picture>
);

EdmundsCarMaxLogo.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  isLazyImage: PropTypes.bool,
};

EdmundsCarMaxLogo.defaultProps = {
  width: 249,
  height: 25,
  isLazyImage: false,
};
