import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { get } from 'lodash';
import Button from 'reactstrap/lib/Button';
import Col from 'reactstrap/lib/Col';
import { EventToolbox } from 'client/utils/event-toolbox';
import { TrackingConstant } from 'client/tracking/constant';
import { CmsEntities } from 'client/data/models/cms';
import { DEFAULT_CONTENT } from 'client/data/cms/content';
import { CTA_TEXTS, SELL_CAR_PAGE_CREATIVE_ID } from 'site-modules/shared/constants/editorial/sell-car';
import { APPRAISE_BY_TAB_HEADERS_CLASSNAME } from 'site-modules/shared/components/appraisal/appraisal-tabs/constants';
import { APPRAISAL_VEHICLE_ENTRY_CREATIVE_ID } from 'site-modules/shared/constants/appraisal/appraisal';
import { getIsEmoSearchEnabled } from 'site-modules/shared/utils/multi-offer/multi-offer';
import { ErrorBoundary } from 'site-modules/shared/components/error-boundary/error-boundary';
import { HowItWorks } from 'site-modules/shared/components/appraisal/how-it-works/how-it-works';
import { WhyEdmunds } from 'site-modules/editorial/components/why-edmunds/why-edmunds';
import { SellMyCarAppraisalTabsContainer } from 'site-modules/editorial/components/sell-my-car/sell-my-car-appraisal-tabs-container';
import { EdmundsCarmaxDisclaimer } from 'site-modules/shared/components/appraisal/edmunds-carmax-disclaimer/edmunds-carmax-disclaimer';

import 'site-modules/shared/components/appraisal/appraisal-common.scss';

export function SellMyCarUI({
  entry,
  initialStateCode,
  appraisalTabsCtaColor,
  location,
  hasAppraisalTabs,
  isBackgroundStyleTabEnabled,
  isSellCarLanding,
  isCombinedEntry,
  isOptimizedTrafficSellCarLandingPage,
}) {
  useEffect(() => {
    if (getIsEmoSearchEnabled(location)) {
      EventToolbox.fireTrackAction({
        event_type: TrackingConstant.EVENT_TYPE_ACTION_COMPLETED,
        event_data: {
          action_name: TrackingConstant.ACTION_SHOW_CONTENT,
          action_category: TrackingConstant.SYSTEM_ACTION_CATEGORY,
          subaction_name: TrackingConstant.MULTI_OFFER_SEARCH,
          creative_id: APPRAISAL_VEHICLE_ENTRY_CREATIVE_ID,
          value: true,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function scrollToAppraiseByTabHeaders() {
    const elem = document.querySelector(`.${APPRAISE_BY_TAB_HEADERS_CLASSNAME}`);
    if (elem) {
      elem.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }

  const hasSellEasyStepsContent = entry.hasChild('sell-easy-steps');
  const hasWhyEdmundsContent = entry.hasChild('why-edmunds');
  const hasInfoSection = hasWhyEdmundsContent || hasSellEasyStepsContent;
  const sellEasyStepsContent = entry.child('sell-easy-steps');
  const whyEdmundsContent = entry.child('why-edmunds');
  const trackingParent = entry.metadata('creativeId').value(SELL_CAR_PAGE_CREATIVE_ID);

  let lgColSize = 8;

  if (isCombinedEntry) {
    lgColSize = 12;
  } else if (isSellCarLanding) {
    lgColSize = 10;
  }

  return (
    <div className="sell-my-car mb-1_5 text-gray-darker" data-tracking-parent={trackingParent}>
      {hasAppraisalTabs && (
        <ErrorBoundary>
          <section className="pb-3_5 appraisal-tabs-container-section">
            <SellMyCarAppraisalTabsContainer
              isBackgroundStyleTabEnabled={isBackgroundStyleTabEnabled}
              appraisalTabsCtaColor={appraisalTabsCtaColor}
              initialStateCode={initialStateCode}
            />
          </section>
        </ErrorBoundary>
      )}
      {hasInfoSection && (
        <div className="sell-my-car-info-section-bg">
          <Col xs={12} lg={lgColSize} className="px-0">
            {!isSellCarLanding && (
              <hr className={classnames('mb-2', { 'invisible mt-0': !hasAppraisalTabs, 'mt-1_5': hasAppraisalTabs })} />
            )}
            <div
              className={classnames('sell-car-info-section sell-car-page-block', {
                'bg-white py-2_5 px-1 px-md-2_5 mb-1_5': isSellCarLanding && !isCombinedEntry,
              })}
            >
              <ErrorBoundary>
                {hasSellEasyStepsContent && (
                  <Fragment>
                    <HowItWorks content={sellEasyStepsContent.child('sell-easy-steps-content')} />
                    {!isOptimizedTrafficSellCarLandingPage && <hr className="mt-1_5 mb-2" />}
                  </Fragment>
                )}
                {!isOptimizedTrafficSellCarLandingPage && (
                  <Fragment>
                    <WhyEdmunds content={whyEdmundsContent.child('why-edmunds-content')} />
                    <Button
                      onClick={scrollToAppraiseByTabHeaders}
                      color="blue-50"
                      className="sell-now-btn text-transform-none"
                      data-tracking-id="view_content"
                      data-tracking-value={CTA_TEXTS.SELL_MY_CAR_NOW}
                    >
                      <span className="size-16">{CTA_TEXTS.SELL_MY_CAR_NOW}</span>
                    </Button>
                    <div className="size-12 text-cool-gray-30 mt-1_5">
                      <EdmundsCarmaxDisclaimer isEdmundsLink />
                    </div>
                  </Fragment>
                )}
              </ErrorBoundary>
            </div>
          </Col>
        </div>
      )}
    </div>
  );
}

SellMyCarUI.propTypes = {
  initialStateCode: PropTypes.string,
  entry: CmsEntities.Content,
  isBackgroundStyleTabEnabled: PropTypes.bool,
  location: PropTypes.shape({}),
  appraisalTabsCtaColor: PropTypes.string,
  hasAppraisalTabs: PropTypes.bool,
  isSellCarLanding: PropTypes.bool,
  isCombinedEntry: PropTypes.bool,
  isOptimizedTrafficSellCarLandingPage: PropTypes.bool,
};

SellMyCarUI.defaultProps = {
  initialStateCode: '',
  entry: DEFAULT_CONTENT,
  isBackgroundStyleTabEnabled: false,
  location: {},
  appraisalTabsCtaColor: undefined,
  hasAppraisalTabs: true,
  isSellCarLanding: false,
  isCombinedEntry: false,
  isOptimizedTrafficSellCarLandingPage: false,
};

const mapStateToProps = state => ({
  isAuthenticated: get(state.profile, 'isAuthenticated'),
  location: get(state, 'pageContext.location'),
});

export const SellMyCar = connect(mapStateToProps)(SellMyCarUI);
