import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useSelector } from 'react-redux';
import { get } from 'lodash';
import { Link } from 'site-modules/shared/components/link/link';

const EDMUNDS_SITE = 'Edmunds.com';

export const EdmundsCarmaxDisclaimer = ({ isEdmundsLink, agreementLinkClassName }) => {
  const isAppExtensionPage = useSelector(state => get(state, 'pageContext.isAppExtensionPage'));
  const extension = isAppExtensionPage ? 'app' : 'html';
  return (
    <>
      {isEdmundsLink ? (
        <Link to="/" className="text-underline">
          {EDMUNDS_SITE}
        </Link>
      ) : (
        EDMUNDS_SITE
      )}
      , Inc. is a wholly owned subsidiary of CarMax, Inc. To learn more about the relationship between Edmunds and
      CarMax, see our{' '}
      <Link
        to={`/about/visitor-agreement.${extension}`}
        className={classnames('text-underline', agreementLinkClassName)}
        rel="noopener noreferrer"
      >
        Visitor Agreement
      </Link>
      .
    </>
  );
};

EdmundsCarmaxDisclaimer.propTypes = {
  isEdmundsLink: PropTypes.bool,
  agreementLinkClassName: PropTypes.string,
};

EdmundsCarmaxDisclaimer.defaultProps = {
  isEdmundsLink: false,
  agreementLinkClassName: '',
};
