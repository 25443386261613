import { BASE_BREADCRUMBS } from 'site-modules/shared/constants/editorial/editorial';
import { SLUGS } from 'site-modules/editorial/constants/slugs';
import { slugToCapitalizedString } from 'client/utils/string-utils';

const CATEGORIES_NAME_MAP = {
  [SLUGS.CAR_REVIEWS]: 'Car Reviews',
};

const canonicalOrigin = 'https://www.edmunds.com';

export function getResearchArticleBreadcrumbs(slugs, title, href) {
  return [
    ...BASE_BREADCRUMBS.HOME,
    ...slugs.map(slug => ({ title: slugToCapitalizedString(slug), href: `/${slug}/` })),
    { title, ...(href && { href }) },
  ];
}

export function getResearchCategoryLandingBreadcrumbs({ category, splat, title }) {
  return splat
    ? [
        ...BASE_BREADCRUMBS.HOME,
        { title: CATEGORIES_NAME_MAP[category] || slugToCapitalizedString(category), href: `/${category}/` },
        { title, href: `/${category}/${splat}/` },
      ]
    : [...BASE_BREADCRUMBS.HOME, { title, href: `/${category}/` }];
}

export function getBreadcrumbsFromConfig(config, pathname, title) {
  let configContent = config;
  if (configContent.children && configContent.children().length > 0) {
    configContent = configContent.children()[0];
  }

  return [
    ...configContent.child('breadcrumb').links(),
    {
      title,
      href: canonicalOrigin + pathname,
    },
  ];
}

export function getAboutArticleBreadcrumbs({ splat, articleId, title }) {
  return [
    ...BASE_BREADCRUMBS.HOME,
    { title: 'About Us', href: '/about/' },
    { title, href: `/about/${splat ? `${splat}/` : ''}${articleId}.html` },
  ];
}

export function getPressArticleBreadcrumbs({ articleId, title }) {
  return [
    ...BASE_BREADCRUMBS.HOME,
    { title: 'About Us', href: '/about/' },
    { title: 'Press', href: '/industry/press/' },
    { title, href: `/about/press/${articleId}.html` },
  ];
}

export function getAffiliateArticleBreadcrumbs({ articleId, title }) {
  const breadcrumbs = [...BASE_BREADCRUMBS.HOME, { title: 'Affiliate Program', href: '/affiliate-article/' }];

  if (articleId) {
    breadcrumbs.push({ title, href: `/affiliate-program/${articleId}.html` });
  }

  return breadcrumbs;
}

export function getEdmundsInfoBreadcrumbs({ articleId, title }) {
  const breadcrumbs = [...BASE_BREADCRUMBS.HOME, { title: 'Edmunds Additional Information', href: '/edmunds-info/' }];

  if (articleId) {
    breadcrumbs.push({ title, href: `/edmunds-info/${articleId}.html` });
  }

  return breadcrumbs;
}

export function getMobileArticleBreadcrumbs({ articleId, title }) {
  return [...BASE_BREADCRUMBS.HOME, { title, href: `/mobile/${articleId}.html` }];
}

export function getTypeBeforeBuyBreadcrumbs({ type, title }) {
  return [
    ...BASE_BREADCRUMBS.HOME,
    { title: 'Car Reviews', href: '/car-reviews/' },
    { title, href: `/${type}/before-buy.html` },
  ];
}

export function getTypeArticlesLandingBreadcrumbs(title) {
  return [...BASE_BREADCRUMBS.HOME, { title: 'Electric Cars', href: '/electric-car/' }, { title }];
}

export function getAuthorPageBreadcrumbs(authorName, pathname) {
  return [
    ...BASE_BREADCRUMBS.HOME,
    { title: 'About', href: '/about/editors.html' },
    { title: authorName, href: pathname },
  ];
}

export function getTypeNewsPageBreadcrumbs(title, pathname) {
  return [...BASE_BREADCRUMBS.HOME, { title: 'Car News', href: '/car-news/' }, { title, href: pathname }];
}

export function getObdDtcBreadcrumbs(title, obdDtcHref, pathname) {
  return [...BASE_BREADCRUMBS.HOME, { title: 'OBD/DTC', href: `/${obdDtcHref}/` }, { title, href: pathname }];
}
