import React from 'react';
import { CmsEntities } from 'client/data/models/cms';
import { CONTENT_CREATIVE_IDS } from 'site-modules/shared/constants/editorial/sell-car';
import { getStaticImageUrl } from 'client/utils/image-helpers';
import { EdmundsCarMaxLogo } from 'site-modules/shared/components/logos/edmunds-carmax-logo';
import { ContentFragment } from 'site-modules/shared/components/content-fragment/content-fragment';

const WHY_EDMUNDS_CHECK_IMG = getStaticImageUrl('/img/eva/why-edmunds.png');

export function WhyEdmunds({ content }) {
  if (!content.hasMetadata('item-1-title')) {
    return null;
  }

  const items = [
    { headline: content.metadata('item-1-title').value(), text: content.metadata('item-1-text').value() },
    { headline: content.metadata('item-2-title').value(), text: content.metadata('item-2-text').value() },
    { headline: content.metadata('item-3-title').value(), text: content.metadata('item-3-text').value() },
  ];

  return (
    <section className="why-edmunds" data-tracking-parent={CONTENT_CREATIVE_IDS.WHY_EDMUNDS}>
      <EdmundsCarMaxLogo isLazyImage />
      <ContentFragment componentToUse="h2" classes="heading-2 mt-1 mb-2">
        {content.title}
      </ContentFragment>
      <ul className="list-unstyled">
        {items.map(({ headline, text }) => (
          <li key={headline} className="d-flex align-items-center mb-1_5">
            <img
              src={WHY_EDMUNDS_CHECK_IMG}
              width={30}
              height={30}
              alt=""
              className="d-block mr-1 align-self-start"
              loading="lazy"
            />
            <div>
              <h3 className="size-16 font-weight-bold text-gray-darker mb-0_25">{headline}</h3>
              <p className="size-16 text-gray-darker mb-0">{text}</p>
            </div>
          </li>
        ))}
      </ul>
    </section>
  );
}

WhyEdmunds.propTypes = {
  content: CmsEntities.Content.isRequired,
};
